<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Third Party Settings</h4>
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="manage_store">
                      <div v-if="!enableEditing">
                        <form class="form-horizontal" @submit.prevent="createSettings">

                          <div class="form-group">
                            <label class="control-label" for="pwd">Channel:</label>
                            <div>
                              <select class="form-control" v-model="thirdParty">
                                <option v-for="channel in channels" :key="channel.channel_name" :value="channel.channel_name">{{channel.channel_name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='uber'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Client ID:</label>
                              <div>
                                <input v-model="clientId"   class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Client Secret:</label>
                              <div>
                                <input  v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div v-if="thirdParty.toLowerCase() =='glovo' || thirdParty.toLowerCase() =='bolt' || thirdParty.toLowerCase() == 'buymore' ">
                            <div class="form-group">
                              <label class="control-label" for="pwd">{{thirdParty.toLowerCase() =='glovo' ? 'Shared Token': 'Integrator Id'}}:</label>
                              <div>
                                <input v-model="accessToken" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div v-if="thirdParty.toLowerCase()=='bolt'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Secret Key:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='jumia'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Username:</label>
                              <div>
                                <input v-model="clientId" class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Password:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='little'">
                            <div class="form-group">
                              <label class="control-label" for="usr">Username:</label>
                              <div>
                                <input v-model="clientId" class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Password:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='piki'">
                            <div class="form-group">
                              <label class="control-label" for="usr">API Key:</label>
                              <div>
                                <input v-model="accessToken" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="this.channelsSupportingGAPI.includes(thirdParty)">
                            <div class="form-group">
                              <label class="control-label" for="pwd">GAPI Version:</label>
                              <div>
                                <select class="form-control" v-model="gApiVersion">
                                  <option v-for="version in gApiVersions" :key="version" :value="version">
                                    v{{version}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label" for="pwd">Status:</label>
                            <div>
                              <select class="form-control" id="" v-model="status">
                                <option :value="true">Activate</option>
                                <option :value="false">Deactivate</option>
                              </select>
                            </div>
                          </div>
                          <div class="submit_btn_section" v-if="thirdParty !=''">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <button type="submit" class="btn btn-primary">
                                    <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Create </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <!-- editting form -->
                      <div v-if="enableEditing">
                        <form class="form-horizontal" @submit.prevent="updateSettings">

                          <div class="form-group">
                            <label class="control-label" for="pwd">Channel:</label>
                            <div>
                              <select class="form-control" v-model="thirdParty">
                                <option v-for="channel in channels" :key="channel.channel_name" :value="channel.channel_name">{{channel.channel_name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='uber'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Client ID:</label>
                              <div>
                                <input v-model="clientId" class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Client Secret:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div v-else-if="thirdParty.toLowerCase() =='glovo' || thirdParty.toLowerCase() =='bolt'||  thirdParty.toLowerCase() == 'buymore'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">{{thirdParty.toLowerCase() =='glovo' ? 'Shared Token': 'Integrator Id'}}:</label>
                              <div>
                                <input v-model="accessToken" class="form-control">
                              </div>
                            </div>

                          </div>
                          <div v-if="thirdParty.toLowerCase()=='bolt'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Secret Key:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>

                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='jumia'">
                            <div class="form-group">
                              <label class="control-label" for="pwd">Username:</label>
                              <div>
                                <input v-model="clientId" class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Password:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='little'">
                            <div class="form-group">
                              <label class="control-label" for="usr">Username:</label>
                              <div>
                                <input v-model="clientId" class="form-control">
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label" for="pwd">Password:</label>
                              <div>
                                <input v-model="clientSecret" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="thirdParty.toLowerCase() =='piki'">
                            <div class="form-group">
                              <label class="control-label" for="usr">API Key:</label>
                              <div>
                                <input v-model="accessToken" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="typeonediv" v-if="this.channelsSupportingGAPI.includes(thirdParty)">
                            <div class="form-group">
                              <label class="control-label" for="pwd">GAPI Version:</label>
                              <div>
                                <select class="form-control" v-model="gApiVersion">
                                  <option v-for="version in gApiVersions" :key="version" :value="version">
                                    v{{version}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label" for="pwd">Status:</label>
                            <div>
                              <select class="form-control" id="" v-model="status">
                                <option :value="true">Activate</option>
                                <option :value="false">Deactivate</option>
                              </select>
                            </div>
                          </div>
                          <div class="submit_btn_section" v-if="thirdParty !=''">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <button type="submit" class="btn btn-orange">
                                    <span v-if="spinner" v-html="updatespinner"></span> <span v-else>Update </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <!-- end editting form -->
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div>
                      <div class="table_section settings">
                        <table class="table">
                          <thead>
                            <tr>
                              <th width="5px">#</th>
                              <th scope="col">Merchant Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Settings</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody v-if="!loading">
                            <tr v-for="(setting,sindx) in settings" :key="setting.id">
                              <td>{{sindx + 1}}.</td>
                              <td>{{setting.merchant_name}}</td>
                              <td>
                                <span :class="[{'active':setting.status},{'disabled':!setting.status}]">
                                  <strong>{{ setting.status ? "Active" :"Deactivated" }}</strong>
                                </span>
                              </td>
                              <td class="tokens">
                                <div>
                                  <!-- {{ setting }} -->
                                  <span><b>Channel:</b> {{setting.channel_name}}</span><br>

                                  <div v-if="setting.channel_name.toLowerCase()=='uber' || setting.channel_name.toLowerCase()=='little' ">
                                    <span><b>Client ID:</b> {{setting.client_id !=''? setting.client_id: 'N/A'}}</span> <br>
                                    <span><b>Client Secret:</b> {{setting.client_secret !=''?setting.client_secret: 'N/A'}}</span> <br>
                                  </div>
                                  <div v-else-if="setting.channel_name.toLowerCase()=='glovo' || setting.channel_name.toLowerCase()=='bolt' || setting.channel_name.toLowerCase() == 'buymore'">
                                    <span> <b>{{setting.channel_name.toLowerCase()=='glovo' ? 'Shared Token' : 'Integrator Id'}} :</b>{{setting.access_token}}</span>
                                    <div v-if="setting.channel_name.toLowerCase() == 'bolt'">
                                      <span><b>Secret Key:</b> {{setting.client_id !=''? setting.client_id: 'N/A'}}</span> <br>
                                    </div>
                                  </div>

                                  <div v-else-if="setting.channel_name.toLowerCase() == 'jumia'">
                                    <span><b>Username:</b> {{setting.client_id !=''? setting.client_id: 'N/A'}}</span> <br>
                                    <span><b>Password:</b> {{setting.client_secret !=''?setting.client_secret: 'N/A'}}</span> <br>
                                  </div>
                                  <div v-else-if="setting.channel_name.toLowerCase() == 'piki'">
                                    <span><b>API Key:</b> {{ setting.access_token || 'N/A'}}</span> <br>
                                  </div>

                                  <div v-if="this.channelsSupportingGAPI.includes(setting.channel_name)">
                                    <hr class="my-2"/>
                                    <h3>GAPI</h3>
                                    <span><b>Access Key:</b> {{setting.gapi_access_key || "N/A"}}</span><br>
                                    <span><b>Secret Key:</b> {{setting.gapi_secret_key || "N/A"}}</span><br>
                                    <span><b>Version:</b> {{setting.gapi_version ? "v" + setting.gapi_version : "N/A"}}</span><br>
                                  </div>


                                </div>
                              </td>

                              <td>
                                <div class="btn-group-vertical" style="width: 100px;">
                                  <button class="btn btn-purple btn-sm mr-2" @click="editSettings(setting)">
                                    <i class='fas fa-edit'></i>
                                    Edit
                                  </button>
                                  <router-link :to="{name: 'activity_log',params:{id: setting.id}, query:{logID: 7}}" class="mt-1 w-100">
                                    <button class="btn btn-sm custom_btn custon_orange w-100">
                                      <i class='fas fa-file'></i>
                                      Logs
                                    </button>
                                  </router-link>
                                  <button v-if="this.user.role.id === UserRoles.superAdmin && this.channelsSupportingGAPI.includes(setting.channel_name)" class="btn btn-purple btn-sm mr-2 mt-1" @click="refreshApiTokens(setting.id)">
                                    <i class='fas fa-arrows-rotate'></i>
                                    GAPI Keys
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot v-if="!loading">
                            <tr>
                              <td colspan="8">
                                <div id="pagination" v-if="totalPages > 1">
                                  <ul class="showItems">
                                    <li>Show Items:
                                      <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                        <option :value="10">10</option>
                                        <option :value="20">20</option>
                                        <option :value="30">30</option>
                                        <option :value="40">40</option>
                                        <option :value="50">50</option>
                                      </select>
                                    </li>

                                  </ul>
                                  <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                                </div>

                              </td>
                            </tr>
                          </tfoot>
                        </table>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
  components: {
    Pagination,
  },
    data() {
        return {
            stores: [],
            // configuresStores:[],
            loading: false,
            page: 1,
            foodieStore: '',
            thrdpartyStore: '',
            thirdParty: '',
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            accessToken: '',
            enterpageno: '',
            secretKey: '',
            // channels: [
            //   {
            //     "channel_name": "UBER"
            //   },
            //   {
            //      "channel_name": "BOLT"
            //   },
            //   {
            //      "channel_name": "JUMIA"
            //   },
            //    {
            //      "channel_name": "GLOVO"
            //   },
            //   {
            //      "channel_name": "BUYMORE"
            //   },
            //   {
            //      "channel_name": "LITTLE"
            //   },
            //   {
            //      "channel_name": "PIKI"
            //   },
            //   {
            //      "channel_name": "DUKA-D"
            //   },
            // ],
            channelsSupportingGAPI: ["PIKI", "DUKA-D", "ANDO"],
            gApiVersions: [2, 1],
            configuredstores: [],
            settings:[],
            clientId: '',
            clientSecret: '',
            settingId: '',
            gApiVersion: 0,
            enableEditing:false,
            spinner: false,
            status:true,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Creating...",
            updatespinner: "<i class='fa fa-spinner fa-spin '></i> Updating..."

        }
    },
    mounted: async function () {
        this.getSettings();
    },
    methods: {
        async createSettings(){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const payload = {
                "merchant_id": parseInt(this.merchantID),
                "client_id": this.clientId,
                "client_secret": this.clientSecret,
                "channel_name" :this.thirdParty,
                "access_token": this.accessToken,
                "gapi_version": this.gApiVersion,
                "status": this.status
            };
            const url = this.baseUrl + '/third-party-setting'
            this.axios.post(url, payload,config).then((response) => {
                    if (response.status === 201) {
                        toast.success("Configuration was successfully");
                        this.spinner = false;
                        this.getSettings();
                    }else {
                        this.spinner = false;
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    this.spinner = false;
                    console.log('error', err);
                });
        },
        async getSettings() {
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id="+this.merchantID;
            this.axios.get(url,config).then((response) => {
                if(response.status === 200){
                    this.loading = false;
                    this.settings = response.data.data;
                }
            }).catch((err) => {
                this.loading = false;
                console.log('error', err);
            })
        },
        editSettings(setting){
          this.enableEditing = true;
          this.settingId = setting.id
          this.thirdParty = setting.channel_name;
          this.clientId = setting.client_id;
          this.accessToken = setting.access_token;
          this.clientSecret = setting.client_secret;
          this.gApiVersion = setting.gapi_version
          this.status = setting.status

        },
        updateSettings(){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
          const payload = {
                "merchant_id":parseInt(this.merchantID),
                "client_id": this.clientId,
                "client_secret": this.clientSecret,
                "channel_name" :this.thirdParty,
                "access_token": this.accessToken,
                "gapi_version": this.gApiVersion,
                "status": this.status
            };
            this.spinner = true;
            const url = this.baseUrl + '/third-party-setting/'+ this.settingId
            this.axios.put(url, payload,config).then((response) => {
                    if (response.status === 200) {
                        toast.success("Update was successful");
                        this.spinner = false;
                        this.getSettings();
                        this.enableEditing = false;
                        this.clientId = '';
                        this.clientSecret = '';
                        this.thirdParty = '';
                    }else {
                        this.spinner = false;
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    this.spinner = false;
                    if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
                });
        },
        refreshApiTokens(id){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.spinner = true;
            const url = this.baseUrl + '/third-party-setting/'+this.merchantID +"/"+ id + "/refresh"
            this.axios.patch(url, null, config).then((response) => {
                    if (response.status === 200) {
                        toast.success("Update was successful");
                        this.spinner = false;
                        this.getSettings();
                    }else {
                        this.spinner = false;
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    this.spinner = false;
                    if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    } else {
                      toast.error(error.response.data.message);
                    }
                });
        },
         onPageChange(page) {
            this.page = page
            this.getConfigureStores();
        },
        onChangeRecordsPerPage() {
            this.getConfigureStores();
            // ds
        }
    },
    mixins: [ReusableDataMixins]
}
</script>
